/*
 * Auth.
 */

// Update Shipping
export const REQUEST_UPDATE_DEAL_STAGE = 'REQUEST_UPDATE_DEAL_STAGE';
export const CANCEL_UPDATE_DEAL_STAGE = 'CANCEL_UPDATE_DEAL_STAGE';
export const UPDATE_DEAL_STAGE = 'UPDATE_DEAL_STAGE';
export const UPDATE_DEAL_STAGE_PENDING = 'UPDATE_DEAL_STAGE_PENDING';
export const UPDATE_DEAL_STAGE_REJECTED = 'UPDATE_DEAL_STAGE_REJECTED';
export const UPDATE_DEAL_STAGE_FULFILLED = 'UPDATE_DEAL_STAGE_FULFILLED';

