/*
 * Auth.
 */

// Update Shipping
export const REQUEST_PAY_COMMISSION = 'REQUEST_PAY_COMMISSION';
export const CANCEL_PAY_COMMISSION = 'CANCEL_PAY_COMMISSION';
export const PAY_COMMISSION = 'PAY_COMMISSION';
export const PAY_COMMISSION_PENDING = 'PAY_COMMISSION_PENDING';
export const PAY_COMMISSION_REJECTED = 'PAY_COMMISSION_REJECTED';
export const PAY_COMMISSION_FULFILLED = 'PAY_COMMISSION_FULFILLED';

