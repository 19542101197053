/*
 * Auth.
 */

// Update Shipping
export const REQUEST_UPDATE_PAYMENTS = 'REQUEST_UPDATE_PAYMENTS';
export const CANCEL_UPDATE_PAYMENTS = 'CANCEL_UPDATE_PAYMENTS';
export const UPDATE_PAYMENTS = 'UPDATE_PAYMENTS';
export const UPDATE_PAYMENTS_PENDING = 'UPDATE_PAYMENTS_PENDING';
export const UPDATE_PAYMENTS_REJECTED = 'UPDATE_PAYMENTS_REJECTED';
export const UPDATE_PAYMENTS_FULFILLED = 'UPDATE_PAYMENTS_FULFILLED';

