import { fromJS } from 'immutable';

import * as constants from '../constants';
import createReducer from '../utils/createReducer';

const initialState = {
  latestOrder: null,
  allOrders: null
};

export default createReducer(fromJS(initialState), {
  [constants.REQUEST_WHOLESALE_PRIORITY_LATEST_ORDER]: (state, action) =>
    state.merge({
      ...initialState
    })
      .merge({
        latestOrder: action.latestOrder
      }),
  [constants.REQUEST_WHOLESALE_PRIORITY_ALL_ORDERS]: (state, action) =>
    state.merge({
      ...initialState
    })
      .merge({
        allOrders: action.allOrders
      }),
  [constants.CANCEL_WHOLESALE_PRIORITY_DIALOGS]: state =>
    state.merge({
      ...initialState
    })
});
