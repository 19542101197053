/*
 * Auth.
 */

// Update Shipping
export const REQUEST_UPDATE_SHIPPING = 'REQUEST_UPDATE_SHIPPING';
export const CANCEL_UPDATE_SHIPPING = 'CANCEL_UPDATE_SHIPPING';
export const UPDATE_SHIPPING = 'UPDATE_SHIPPING';
export const UPDATE_SHIPPING_PENDING = 'UPDATE_SHIPPING_PENDING';
export const UPDATE_SHIPPING_REJECTED = 'UPDATE_SHIPPING_REJECTED';
export const UPDATE_SHIPPING_FULFILLED = 'UPDATE_SHIPPING_FULFILLED';

