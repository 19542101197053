/*
 * Auth.
 */

// Update Shipping
export const REQUEST_UPDATE_DATES = 'REQUEST_UPDATE_DATES';
export const CANCEL_UPDATE_DATES = 'CANCEL_UPDATE_DATES';
export const UPDATE_DATES = 'UPDATE_DATES';
export const UPDATE_DATES_PENDING = 'UPDATE_DATES_PENDING';
export const UPDATE_DATES_REJECTED = 'UPDATE_DATES_REJECTED';
export const UPDATE_DATES_FULFILLED = 'UPDATE_DATES_FULFILLED';

