import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectContainer from 'redux-static';

import { reportActions } from '../../../actions';
import { Confirm } from '../../../components/Dashboard';
import AllOrdersTable from '../../../components/Reports/AllOrdersTable';
import './WholesalePriorityDialog.css';

export default connectContainer(class DisplayAllOrdersLineItemsDialog extends Component {
  constructor(props) {
    super(props);
  }

  static stateToProps = (state) => ({
    wholesalePriorityDialogs: state.wholesalePriorityDialogs,
  });

  static actionsToProps = {
    ...reportActions
  }

  static propTypes = {
    wholesalePriorityDialogs: PropTypes.object.isRequired,
    cancelWholesalePriorityDialogs: PropTypes.func.isRequired
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.wholesalePriorityDialogs !== this.props.wholesalePriorityDialogs;
  }

  onConfirm = () => {
    this.props.cancelWholesalePriorityDialogs();
  };

  render() {
    const { allOrders } = this.props.wholesalePriorityDialogs.toJS();

    return (
      <Confirm title="All Orders" show={allOrders !== null} loading={false} onCancel={this.onConfirm}
               onConfirm={this.onConfirm} dialogClassName={'wholesale-priority-dialog'}>
        <AllOrdersTable lineItems={allOrders || []} />
      </Confirm>
    );
  }
});
