import React, { Component } from 'react';

import {
  Table,
  TableBody,
  TableTextCell,
  TableHeader,
  TableColumn,
  TableRow
} from '../Dashboard';

export default class LatestOrderTable extends Component {
  static propTypes = {
    lineItems: React.PropTypes.array.isRequired
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.lineItems !== this.props.lineItems;
  }

  render() {
    const lineItems = this.props.lineItems || [];

    return (
      <Table>
        <TableHeader>
          <TableColumn width="50%">Item</TableColumn>
          <TableColumn width="10%">Quantity</TableColumn>
        </TableHeader>
        <TableBody>
          {lineItems.map((lineItem, index) => {

            return <TableRow key={index}>
              <TableTextCell>{lineItem.name}</TableTextCell>
              <TableTextCell>{lineItem.quantity}</TableTextCell>
            </TableRow>;
          })}
        </TableBody>
      </Table>
    );
  }
}
