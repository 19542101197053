import React, { Component, PropTypes } from 'react';
import connectContainer from 'redux-static';
import formatCurrency from 'format-currency';
import { Button } from 'react-bootstrap';

import { reportActions } from '../../actions';

import { Error, LoadingPanel } from '../../components/Dashboard';
import {
  WholesalePriorityReportTable
} from '../../components/Reports';

import DisplayLatestOrderLineItemsDialog from './Dialogs/DisplayLatestOrderLineItemsDialog';
import DisplayAllOrdersLineItemsDialog from "./Dialogs/DisplayAllOrdersLineItemsDialog";

export default connectContainer(class WholesalePriorityReport extends Component {
  static stateToProps = (state) => ({
    wholesalePriorityReport: state.wholesalePriorityReport
  });

  static actionsToProps = {
    ...reportActions
  }

  static propTypes = {
    params: PropTypes.object,
    wholesalePriorityReport: PropTypes.object,
    fetchWholesalePriorityReport: React.PropTypes.func.isRequired,
    requestWholesalePriorityLatestOrder: React.PropTypes.func.isRequired,
    requestWholesalePriorityAllOrders: React.PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.fetchWholesalePriorityReport();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.wholesalePriorityReport !== this.props.wholesalePriorityReport;
  }

  showLatestOrder(latestOrder) {
    console.log('Carlos: latestOrder', latestOrder);

    return this.props.requestWholesalePriorityLatestOrder(latestOrder);
  }

  showAllOrders(allOrders) {
    console.log('Carlos: allOrders', allOrders);
    return this.props.requestWholesalePriorityAllOrders(allOrders);
  }

  render() {
    const { error, report, loading } = this.props.wholesalePriorityReport.toJS();

    const { storesThatHaventPaid, storesWithOverdueTasks, happyStores , urgentStores, lessUrgentStores } = report;

    const opts = { format: '%s%v', symbol: '$' };

    return (
      <div className="order">
        <div className="row content-header">
          <div className="col-xs-12">
            <h2 className="pull-left">Wholesale Priority Report</h2>
          </div>
        </div>
        <LoadingPanel show={loading}>
          <div className="row">
            <div className="col-xs-12 wrapper">
              <Error message={error}/>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h2>Stores That Owe BFD Money</h2>
            </div>
            <div className="col-xs-12">
              <WholesalePriorityReportTable stores={ storesThatHaventPaid || [] } showLatestOrder={this.showLatestOrder.bind(this)} showAllOrders={this.showAllOrders.bind(this)} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h2>Stores That Have Overdue Follow Up Tasks (NOTE: These may be repeated in other tables)</h2>
            </div>
            <div className="col-xs-12">
              <WholesalePriorityReportTable stores={ storesWithOverdueTasks || [] } showLatestOrder={this.showLatestOrder.bind(this)} showAllOrders={this.showAllOrders.bind(this)} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h2>Urgent Stores (6-weeks to 6-months since their last order)</h2>
            </div>
            <div className="col-xs-12">
              <WholesalePriorityReportTable stores={ urgentStores || [] } showLatestOrder={this.showLatestOrder.bind(this)} showAllOrders={this.showAllOrders.bind(this)}/>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h2>Less Urgent Stores (6-months or more since their last order)</h2>
            </div>
            <div className="col-xs-12">
              <WholesalePriorityReportTable stores={ lessUrgentStores || [] } showLatestOrder={this.showLatestOrder.bind(this)} showAllOrders={this.showAllOrders.bind(this)}/>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h2>Happy Stores (less than 6-weeks since their last order)</h2>
            </div>
            <div className="col-xs-12">
              <WholesalePriorityReportTable stores={ happyStores || [] } showLatestOrder={this.showLatestOrder.bind(this)} showAllOrders={this.showAllOrders.bind(this)}/>
            </div>
          </div>
        </LoadingPanel>
        <DisplayLatestOrderLineItemsDialog />
        <DisplayAllOrdersLineItemsDialog />
      </div>
    );

  }
});
