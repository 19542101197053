// Login.
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REFRESH_PENDING = 'REFRESH_PENDING';

// Logout.
export const LOGOUT = 'LOGOUT';
export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// AUTHORIZE_HUB_SPOT.
export const AUTHORIZE_HUB_SPOT_STATE = 'AUTHORIZE_HUB_SPOT_STATE';
export const AUTHORIZE_HUB_SPOT = 'AUTHORIZE_HUB_SPOT';
export const AUTHORIZE_HUB_SPOT_PENDING = 'AUTHORIZE_HUB_SPOT_PENDING';
export const AUTHORIZE_HUB_SPOT_REJECTED = 'AUTHORIZE_HUB_SPOT_REJECTED';
export const AUTHORIZE_HUB_SPOT_SUCCESS = 'AUTHORIZE_HUB_SPOT_SUCCESS';

