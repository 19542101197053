import React, { Component } from 'react';
import formatCurrency from 'format-currency';

import {
  Table,
  TableActionCell,
  TableBody,
  TableTextCell,
  TableHeader,
  TableColumn,
  TableRow, TableRouteCell
} from '../Dashboard';
import {Button} from "react-bootstrap";

export default class WholesalePriorityReportTable extends Component {
  static propTypes = {
    stores: React.PropTypes.array.isRequired,
    showLatestOrder: React.PropTypes.func.isRequired,
    showAllOrders: React.PropTypes.func.isRequired
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.stores !== this.props.stores;
  }

  render() {
    const opts = { format: '%s%v', symbol: '$' };
    const stores = this.props.stores || [];

    return (
      <Table>
        <TableHeader>
          <TableColumn width="15%">Store</TableColumn>
          <TableColumn width="10%">Next Follow Up Due</TableColumn>
          <TableColumn width="10%">Oldest Payment Due</TableColumn>
          <TableColumn width="10%">Latest Shipment Date</TableColumn>
          <TableColumn width="10%">Latest Payment Date</TableColumn>
          <TableColumn width="10%">First Order Date</TableColumn>
          <TableColumn width="30%">Actions</TableColumn>
        </TableHeader>
        <TableBody>
          {stores.map((store, index) => {
            const now = moment().unix();

            // Check next Payment
            store.nextPaymentStatus = 'greenStatus';
            if (!store.isPaid) {
              if (store.oldestDueDate < now) {
                store.nextPaymentStatus = 'redStatus';
              }
              store.oldestDueDate = moment.unix(store.oldestDueDate).format('MM-DD-YYYY');
            } else {
              store.oldestDueDate = 'PAID';
            }

            // Check next Due Date
            store.nextFollowUpStatus = 'beigeStatus';
            if (!store.nextFollowUpDueDate) {
              store.nextFollowUpDueDate = 'No Follow Up Scheduled';
            } else {
              if (store.nextFollowUpDueDate < now) {
                store.nextFollowUpStatus = 'redStatus';
              } else {
                store.nextFollowUpStatus = 'greenStatus';
              }
              store.nextFollowUpDueDate = moment.unix(store.nextFollowUpDueDate).format('MM-DD-YYYY');
            }

            const latestOrder = Object.entries(store.lineItems)
              .filter(entry => entry[1].latestShippedDate === store.latestShippedDate)
              .map(entry => ({
                name: entry[0],
                quantity: entry[1].latestShippedQuantity
              }))
              .sort((a,b) => a.name < b.name ? -1 : ((a.name === b.name) ? 0 : 1));;
            const allOrders = Object.entries(store.lineItems).map(entry => ({...entry[1], name: entry[0]})).sort((a,b) => a.name < b.name ? -1 : ((a.name === b.name) ? 0 : 1));

            const showLatestOrder = this.props.showLatestOrder;
            const showAllOrders = this.props.showAllOrders;

            return <TableRow key={index}>
              <TableRouteCell
                route={`https://app.hubspot.com/contacts/4239642/record/0-2/${store.id}`} target='_new'>{store.name}</TableRouteCell>
              <TableTextCell className={store.nextFollowUpStatus}>{store.nextFollowUpDueDate}</TableTextCell>
              <TableTextCell className={store.nextPaymentStatus}>{store.oldestDueDate}</TableTextCell>
              <TableTextCell>{moment.unix(store.latestShippedDate).format('MM-DD-YYYY')}</TableTextCell>
              <TableTextCell>{moment.unix(store.latestPaymentDate).format('MM-DD-YYYY')}</TableTextCell>
              <TableTextCell>{moment.unix(store.firstShippedDate).format('MM-DD-YYYY')}</TableTextCell>
              <TableActionCell>
                <Button onClick={() => showLatestOrder(latestOrder)}>Latest Order</Button>
                <Button onClick={() => showAllOrders(allOrders)}>All Orders</Button>
              </TableActionCell>
            </TableRow>;
          })}
        </TableBody>
      </Table>
    );
  }
}
