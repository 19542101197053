/*
 * Auth.
 */

// Update Shipping
export const REQUEST_UPDATE_SHIPPING_INFO = 'REQUEST_UPDATE_SHIPPING_INFO';
export const CANCEL_UPDATE_SHIPPING_INFO = 'CANCEL_UPDATE_SHIPPING_INFO';
export const UPDATE_SHIPPING_INFO = 'UPDATE_SHIPPING_INFO';
export const UPDATE_SHIPPING_INFO_PENDING = 'UPDATE_SHIPPING_INFO_PENDING';
export const UPDATE_SHIPPING_INFO_REJECTED = 'UPDATE_SHIPPING_INFO_REJECTED';
export const UPDATE_SHIPPING_INFO_FULFILLED = 'UPDATE_SHIPPING_INFO_FULFILLED';

