import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectContainer from 'redux-static';

import { reportActions } from '../../../actions';
import { Confirm } from '../../../components/Dashboard';
import LatestOrderTable from '../../../components/Reports/LatestOrderTable';
import './WholesalePriorityDialog.css';

export default connectContainer(class DisplayLatestOrderLineItemsDialog extends Component {
  constructor(props) {
    super(props);
  }

  static stateToProps = (state) => ({
    wholesalePriorityDialogs: state.wholesalePriorityDialogs,
  });

  static actionsToProps = {
    ...reportActions
  }

  static propTypes = {
    wholesalePriorityDialogs: PropTypes.object.isRequired,
    cancelWholesalePriorityDialogs: PropTypes.func.isRequired
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.wholesalePriorityDialogs !== this.props.wholesalePriorityDialogs;
  }

  onConfirm = () => {
    this.props.cancelWholesalePriorityDialogs();
  };

  render() {
    const { latestOrder } = this.props.wholesalePriorityDialogs.toJS();

    return (
      <Confirm title="Latest Order" show={latestOrder !== null} loading={false} onCancel={this.onConfirm}
               onConfirm={this.onConfirm} dialogClassName={'wholesale-priority-dialog'}>
        <LatestOrderTable lineItems={latestOrder || []} />
      </Confirm>
    );
  }
});
