import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectContainer from 'redux-static';
import { Button } from 'react-bootstrap';

import { fetchCompanyPurchasesReport } from '../../actions/report';

import ChooseShowAndYearDialog from './Dialogs/ChooseShowAndYear';

import { Error, LoadingPanel } from '../../components/Dashboard';

export default connectContainer(class CompanyPurchasesReport extends Component {
    static stateToProps = (state) => ({
        reportState: state.companyPurchasesReport.toJS(),
    });

    static actionsToProps = {
        fetchCompanyPurchasesReport
    };

    static propTypes = {
        query: PropTypes.object,
        report: PropTypes.object,
        fetchCompanyPurchasesReport: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired
    };

    fetchReport() {
        this.props.fetchCompanyPurchasesReport(this.props.query);
    }

    componentWillMount() {
        this.fetchReport();
    };

    shouldComponentUpdate(nextProps) {
        return nextProps.ordersForShow !== this.props.ordersForShow ||
            nextProps.params !== this.props.params;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.query !== this.props.query && this.props.query) {
            this.props.fetchCompanyPurchasesReport(this.props.query);
        }
    }

    getNewReport() {
        this.props.router.push('/report/companyPurchases')
    }

    render() {
        const { loading, error } = this.props.reportState;
        const opts = { format: '%s%v', symbol: '$' };

        const hrStyle = {
            height: '2px',
            'background-color': '#555',
            'margin-top': '20px',
            'margin-bottom': '20px',
            width: '95%'
        };

        const paymentSchedule = {};

        return (
            <div className="order">
                <LoadingPanel show={loading}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6 wrapper">
                            <Error message={error}/>
                        </div>
                        <div className={"col-xs-12 col-md-6 pull-right"}>
                            <Button onClick={this.getNewReport.bind(this)}>
                                Choose a Different Report
                            </Button>
                        </div>
                    </div>
                    <h1 show={!loading}>Success!</h1>
                </LoadingPanel>
            </div>
        );

    }
});
